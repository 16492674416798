import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { DefaultHeader } from "components/headers/default-header"
import { graphql } from "gatsby"

const Blog = ({ location, data }) => {
  const {
    allShowcaseJson: { nodes: showcases },
  } = data
  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="導入事例"
          pagepath={location.pathname}
          pagedesc="ブログ一覧 | PARKLoT(パークロット)は「月額制」で「何回でも」実施できるTwitterキャンペーンツールを提供しています。"
        />
        <main className="information">
          <section className="information__heading py-10 px-4 md:pt-14 md:pb-16">
            <div className="text-center">
              <h1 className="text-white font-bold">PARKLoT 導入事例</h1>
            </div>
          </section>
          <section className="md:py-16 pb-16 pt-4 l-container">
            <ul className="md:flex md:flex-wrap blog-list__container">
              {showcases.map((blog, index) => {
                return (
                  <li key={index}>
                    <a className="blog-list__link" href={blog.href}>
                      <GatsbyImage
                        image={getImage(blog.image)}
                        layout="constrained"
                        placeholder="tracedSVG"
                        alt={blog.alt}
                      />
                      <div className="mt-4 mb-2">
                        <span className="px-3 py-1 bg-primary text-white rounded text-xs inline-block">
                          {blog.type}
                        </span>
                      </div>
                      <p className="font-bold mb-3">{blog.title}</p>
                      <div className="blog-list__meta">
                        <p className="text-xs mb-1">{blog.date}</p>
                        <p className="text-xs font-bold">
                          本記事は{blog.minutes}で読むことができます
                        </p>
                      </div>
                    </a>
                  </li>
                )
              })}
            </ul>
          </section>
        </main>
      </Layout>
    </>
  )
}

export const query = graphql`
  query GetBlogs {
    allShowcaseJson {
      nodes {
        id
        title
        href
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        alt
        date
        type
        minutes
      }
    }
  }
`

export default Blog
